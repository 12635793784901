import React from 'react'
import styled from 'styled-components'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import '../styles/rsot.css'

import Layout from '../layouts'
import SEO from '../components/seo'
import BG from '../components/bg'

import RSOTIcon from '../images/rst.inline.svg'

const StyledRoadmap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  padding: 0 2rem;
  padding-bottom: 4rem;
  margin-bottom: 4rem;
  padding-top: 2rem;

  border-bottom: 1px solid ${({ theme }) => theme.colors.grey2};

  @media (max-width: 960px) {
    flex-direction: column;
    grid-template-columns: 1fr;
    margin-top: 0rem;
    padding-top: 1rem;
  }

  @media (max-width: 560px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

const StyledSectionFlex = styled.div`
  padding: 0 0 4rem 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  // max-width: 960px;
  // margin-left: 5rem;
  // text-align: center;
  @media (max-width: 1024px) {
    padding: 1rem;
    margin-top: 0rem;
    // flex-direction: ${({ wrapSmall }) => (!wrapSmall ? 'row' : 'column')};
  }
  @media (max-width: 960px) {
    padding: 1rem;
    margin-left: 0;
    margin-top: 0rem;
    width: 100%;
    // flex-direction: column;
    justify-content: space-between;
  }
  @media (max-width: 560px) {
    padding-left: 0.1rem;
    padding-right: 0.1rem;
    margin-left: 0;
    margin-top: 0rem;
    width: 100%;
    // flex-direction: column;
    justify-content: center;
  }
  h1,                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
  h2 {
    max-width: 650px;
  }
  p {
    /* margin-bottom: 0.5rem; */
    max-width: 650px;
  }
`

const StyledSectionTitle = styled.h3`
  // max-width: 960px;
  line-height: 140%;
  font-size: 32px;
  @media (max-width: 640px) {
    text-align: left;
  }
`

const StyledUL = styled.ul`
  list-style-type: circle;

  li {
    margin-bottom: 0;
  }
`

const Roadmap = props => {
    return (
        <Layout path={props.location.pathname}>
          <BG />
    
          <SEO title="Roadmap" path={props.location.pathname} />
  
          <StyledRoadmap>
            <StyledSectionTitle style={{ textAlign: `center` }}>
              Roadmap
            </StyledSectionTitle>
            <StyledSectionFlex>
                <VerticalTimeline lineColor='black' className='timeline'>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="Q3 2023"
                        iconStyle={{ background: '#fff', color: '#fff' }}
                        icon={<RSOTIcon />}
                    >
                        <h3 className="vertical-timeline-element-title">
                            <span style={{ fontWeight: `bold` }}>Q3</span> 2023
                        </h3>
                        <p>
                        </p>
                            <StyledUL>
                                <li>Uncollateralised lending/borrowing Go-Live</li>
                                <li>AMA (X2)</li>
                                <li>Global partnerships, e.g. Uniswap, Aave and etc.</li>
                                <li>Deflationary strategy (token burn)</li>
                            </StyledUL>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="Q2 2023"
                        iconStyle={{ background: '#fff', color: '#fff' }}
                        icon={<RSOTIcon />}
                    >
                        <h3 className="vertical-timeline-element-title">
                            <span style={{ fontWeight: `bold` }}>Q2</span> 2023
                        </h3>
                        <p>
                        </p>
                            <StyledUL>
                                <li>Sniper bot Go-Live</li>
                                <li>Uncollateralised lending/borrowing beta trial (WRSOT)</li>
                                <li>AMA (X2)</li>
                                <li>Binance & Huobi listing</li>
                                <li>Multi-chain DeFi protocol launch</li>
                            </StyledUL>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="Q1 2023"
                        iconStyle={{ background: '#fff', color: '#fff' }}
                        icon={<RSOTIcon />}
                    >
                        <h3 className="vertical-timeline-element-title">
                            <span style={{ fontWeight: `bold` }}>Q1</span> 2023
                        </h3>
                        <p>
                        </p>
                            <StyledUL>
                                <li>Partnership with Nikolay Shkilev</li>
                                <li>Launch of RSOT purchase via Paypal/Credit/Debit Card</li>
                                <li>Pre-sale II</li>
                                <li>AMA (X2)</li>
                                <li>Sniper bot development</li>
                                <li>Sniper bot beta trial</li>
                                <li>Uncollateralised Lending Protocol development</li>
                                <li>Multi-chain development</li>
                                <li>Pancakeswap listing</li>
                            </StyledUL>
                        <a id='now' />
                    </VerticalTimelineElement>                    
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                        date="Q4 2022"
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        icon={<RSOTIcon />}
                    >
                        <h3 className="vertical-timeline-element-title">
                            <span style={{ fontWeight: `bold` }}>Q4</span> 2022
                        </h3>
                        <p>
                        </p>
                            <StyledUL>
                                <li>Pre-sale I and marketing</li>
                                <li>AI signal trading Go-Live</li>
                                <li>Influencers&apos; partnerships</li>
                                <li>Coinmarketcap & CoinGecko listing</li>
                                <li>AMA (X2)</li>
                                <li>Farming & pool launch</li>
                            </StyledUL>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        date="Q4 2021 - Q3 2022"
                        iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
                        icon={<RSOTIcon />}
                    >
                        <h3 className="vertical-timeline-element-title">
                        <span style={{ fontWeight: `bold` }}>Q4</span> 2021 - <span style={{ fontWeight: `bold` }}>Q3</span> 2022
                        </h3>
                        <p>
                        </p>
                            <StyledUL>
                                <li>DApps / website dev</li>
                                <li>Whitepaper review</li>
                                <li>Community leaders onboarding</li>
                                <li>Developer team formation</li>
                                <li>Private Sale</li>
                                <li>Rissot.to site launch</li>
                                <li>AI signal-trading beta trial</li>
                            </StyledUL>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        date="Q3 2021"
                        iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
                        icon={<RSOTIcon />}
                    >
                        <h3 className="vertical-timeline-element-title">
                            <span style={{ fontWeight: `bold` }}>Q3</span> 2021
                        </h3>
                        <p>
                        </p>
                            <StyledUL>
                                <li>Idea conceptualizing</li>
                                <li>Whitepaper drafting</li>
                                <li>Core Team formation</li>
                            </StyledUL>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        date="Start"
                        dateClassName='timeline-start'
                        contentStyle={{ padding: 0 }}
                        iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
                        icon={<RSOTIcon />}
                    >
                    </VerticalTimelineElement>
                </VerticalTimeline>
              </StyledSectionFlex>
            </StyledRoadmap>
        </Layout>
    )
}

export default Roadmap